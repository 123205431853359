<template>
    <video ref="videoPlayer" class="video-js"></video>
</template>
<script>
    import videojs from 'video.js';
    // import 'video.js/dist/video-js.css'

    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            options: {
                default: () => {
                    return {
                        controls: true,
                        autoplay: false,
                        width: "600px",
                        height: "400px"

                    }
                }
            }
        },
        data () {
            return {
                player: null
            }
        },
        computed: {},
        methods: {},
        created() {},
        mounted() {
            this.$nextTick(function() {
                this.player = videojs(
                    this.$refs['videoPlayer'],
                    this.options,
                    function onPlayerReady() {
                        // console.log('onPlayerReady', this);
                })

            })
        },
        beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
        }
    }
</script>
<style scoped lang="less">
.video-js {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>